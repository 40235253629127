import PlusIcon from "../icons/plus";
import Pointer3d from "../icons/Pointer3d";
import QuestionMarkIcon from "../icons/questionMarkIcon";
import { faq } from "@src/constants";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { useScrollContext } from "@src/context/scroll-context";

export default function FAQ() {
  const {faqRef} = useScrollContext()
  return (
    <div className="w-full flex flex-col items-center justify-center py-24 gap-12 relative overflow-clip" ref={faqRef}>
      <div className="w-full h-[220px] bg-section-divider fixed top-0 translate-y-[-50%]" />
      <div className="w-full -z-10  h-[220px] bg-section-divider-light fixed bottom-0 translate-y-[50%] flex items-center justify-center">
        <div className="h-[1px] w-full bg-layout-divider" />
      </div>
      <Pointer3d className="absolute right-1/4 top-[200px] max-sm:top-[120px] max-sm:right-2" />
      <Pointer3d className="absolute -left-9 rotate-90 top-[500px] max-sm:top-[460px]" />
      <div className="flex flex-col gap-4 items-center">
        <div className="flex items-center gap-2 ">
          <QuestionMarkIcon color="#6106FF" />
          <p className="purple-gradient-text tiny-text font-bold">
            {faq.title}
          </p>
        </div>
        <p className="font-bold h40 max-sm:text-[24px] max-sm:leading-[36px] max-sm:tracking-[-0.47px]">
          Frequently Asked Questions
        </p>
        <p className="text-gray-400 body16 font-medium text-center">
          Find questions and answers related to the
          <span className="font-bold"> DrepID </span>, <br></br> purchase,
          updates, and support.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-y-3 max-sm:px-5">
        {faq.questions.map((item) => (
          <Disclosure key={item.question} >
            <DisclosureButton className={"group"}>
              <div className="flex items-center rounded-xl p-4 w-[564px] max-sm:w-full justify-between border-[1px] border-[#FFFFFF14] bg-faq-question-box">
                <span className="body16 font-medium">{item.question}</span>
                <PlusIcon className="group-data-[open]:rotate-180 duration-500"/>
              </div>
            </DisclosureButton>
            <DisclosurePanel
              className={
                "w-[564px] max-sm:w-full origin-top transition ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 duration-500"
              }
              transition={true}
            >
              <div className="bg-faq-question-box p-2 body14 rounded-lg px-4 text-[14px]">
                {item.answer}
              </div>
            </DisclosurePanel>
          </Disclosure>
        ))}
      </div>
      <p className="body16">
        Contact us at &nbsp;
        <span className="font-semibold underline decoration-0">
          support@drep.id
        </span>{" "}
        via email!
      </p>
    </div>
  );
}
