import { lovelaceToAda , formatAda } from "@src/utils/dRepUtils";
import LandingPageDrepCard from "../cards/LandingPageDrepCard";
import { useGetMintedDRepMetadataQuery } from "@src/store/drep/api";

export default function Dreps() {

  const { data: dReps } = useGetMintedDRepMetadataQuery(6);

  return (
    <div className="flex w-full overflow-x-clip justify-center max-sm:justify-start">
      {(dReps || []).map((drep, index) => (
        <LandingPageDrepCard
          key={`drep.meta.givenName${index}`}
          name =  {drep.givenName}
          dRepHandle = {drep.tokenNames[0] || ''}
          votingPower= {formatAda(lovelaceToAda(drep.activeVotingPower || 0))}
          coverPhoto={`/dReps/dRep-cover-${index + 1}.png`}
          profilePhoto={drep.image || "/dReps/default-dRep-profile.png"}
        />
      ))}
    </div>
  );
}
